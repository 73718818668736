/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SenderMetadataResponse } from './SenderMetadataResponse';

/**
 * Represents the message stored in application. Contains all the data related to the message - content, owner, sender and receive timestamp
 */
export type GetMessagesResponseItem = {
    /**
     * Id of the message itself
     */
    id: string;
    /**
     * Id of the user to whom the message belongs
     */
    messageOwnerId: string;
    /**
     * Timestamp at the moment in time when message was processed by the application
     */
    messageReceiveTimestamp: string;
    /**
     * Unique identifier of the sender, represented as string in format determined by sender type
     */
    senderId: string;
    /**
     * Type of author of the message
     */
    senderType: GetMessagesResponseItem.senderType;
    senderMetadata?: SenderMetadataResponse;
    /**
     * Mime type describing format in which the content value is passed
     */
    contentType: string;
    /**
     * Content of the message
     */
    contentValue?: string;
    /**
     * Additional properties related to the message, in key:value form
     */
    metadata?: Record<string, any>;
};

export namespace GetMessagesResponseItem {

    /**
     * Type of author of the message
     */
    export enum senderType {
        USER = 'USER',
        SYSTEM = 'SYSTEM',
    }


}

