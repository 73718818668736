/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents displayable metadata about the message sender.
 */
export type AuthorMetadataResponse = {
    /**
     * The human-readable name of the author.
     */
    displayName?: string;
    /**
     * The human-readable role of the author.
     */
    displayRole?: string;
    /**
     * The role of the sender.
     */
    role?: AuthorMetadataResponse.role;
};

export namespace AuthorMetadataResponse {

    /**
     * The role of the sender.
     */
    export enum role {
        ANONYMOUS = 'ANONYMOUS',
        CHANGE_PASSWORD = 'CHANGE_PASSWORD',
        API_DOCUMENTATION = 'API_DOCUMENTATION',
        USER = 'USER',
        STAFF_VIEW_ONLY = 'STAFF_VIEW_ONLY',
        STAFF = 'STAFF',
        STAFF_ADMIN_ACCESS = 'STAFF_ADMIN_ACCESS',
        STAFF_CUSTOMER_SUPPORT = 'STAFF_CUSTOMER_SUPPORT',
        STAFF_DIABETES_EDUCATOR = 'STAFF_DIABETES_EDUCATOR',
        STAFF_PROVIDER = 'STAFF_PROVIDER',
        STAFF_PHYSICIAN = 'STAFF_PHYSICIAN',
        STAFF_PHYSICIAN_ASSISTANT = 'STAFF_PHYSICIAN_ASSISTANT',
        STAFF_NURSE_PRACTITIONER = 'STAFF_NURSE_PRACTITIONER',
        DEVELOPER = 'DEVELOPER',
        SUPER_ADMIN = 'SUPER_ADMIN',
    }


}

