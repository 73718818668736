/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminAddAllergyIntoleranceRequest = {
    /**
     * Patient's id.
     */
    userId: string;
    /**
     * Allergy-intolerance's start month
     */
    onsetMonth?: AdminAddAllergyIntoleranceRequest.onsetMonth;
    /**
     * Allergy-intolerance's start year
     */
    onsetYear?: number;
    /**
     * Allergy-intolerance's end month
     */
    abatementMonth?: AdminAddAllergyIntoleranceRequest.abatementMonth;
    /**
     * Allergy-intolerance's end year
     */
    abatementYear?: number;
    /**
     * Allergy-intolerance's identifier
     */
    name: string;
    /**
     * Allergy-intolerance's reaction description
     */
    reaction: string;
    /**
     * Allergy-intolerance's clinical status
     */
    clinicalStatus: AdminAddAllergyIntoleranceRequest.clinicalStatus;
    /**
     * Allergy-intolerance's type
     */
    type: AdminAddAllergyIntoleranceRequest.type;
};

export namespace AdminAddAllergyIntoleranceRequest {

    /**
     * Allergy-intolerance's start month
     */
    export enum onsetMonth {
        JANUARY = 'JANUARY',
        FEBRUARY = 'FEBRUARY',
        MARCH = 'MARCH',
        APRIL = 'APRIL',
        MAY = 'MAY',
        JUNE = 'JUNE',
        JULY = 'JULY',
        AUGUST = 'AUGUST',
        SEPTEMBER = 'SEPTEMBER',
        OCTOBER = 'OCTOBER',
        NOVEMBER = 'NOVEMBER',
        DECEMBER = 'DECEMBER',
    }

    /**
     * Allergy-intolerance's end month
     */
    export enum abatementMonth {
        JANUARY = 'JANUARY',
        FEBRUARY = 'FEBRUARY',
        MARCH = 'MARCH',
        APRIL = 'APRIL',
        MAY = 'MAY',
        JUNE = 'JUNE',
        JULY = 'JULY',
        AUGUST = 'AUGUST',
        SEPTEMBER = 'SEPTEMBER',
        OCTOBER = 'OCTOBER',
        NOVEMBER = 'NOVEMBER',
        DECEMBER = 'DECEMBER',
    }

    /**
     * Allergy-intolerance's clinical status
     */
    export enum clinicalStatus {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
        RESOLVED = 'RESOLVED',
        NULL = 'NULL',
    }

    /**
     * Allergy-intolerance's type
     */
    export enum type {
        ALLERGY = 'ALLERGY',
        INTOLERANCE = 'INTOLERANCE',
        NULL = 'NULL',
    }


}

